$border-prop: 1px solid #A37668;

.side-nav {
  .menu {
    display: flex;
    overflow-y: hidden;
  }
  .menu-item__active {
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.1)
  }
}

.sidebar-element{
  z-index: 9999;
}
.menu-first{
  height: 94%;
}










