@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  input.hidden-arrows[type="number"]::-webkit-inner-spin-button,
  input.hidden-arrows[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input.hidden-arrows[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
}

@media only screen and (max-width: 570px) {
  #root {
    grid-template-columns: 1fr;
    grid-template-rows: 0.4fr 0.4fr auto 0.4fr;
    grid-template-areas:
      "header"
      "Sidebar"
      "main"
      "footer";
  }
}
