.main-content {
  padding-left: 155px; /* width of sidebar */
  height: calc(100vh - 150px);
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 1024px) {
    padding: 0px 10px;
    height: calc(100vh - 53px);
  }
  @media (max-width: 768px) {
    padding: 0px;
  }
}

body {
  font-family: Matter;
}

.swiper-wrapper {
  display: flex !important;
  align-items: start !important;
}

.articles .swiper-wrapper {
  display: flex !important;
  align-items: center !important;
}

.events .swiper-wrapper {
  min-height: 1350px;
  align-items: stretch !important;
  @media (max-width: 2100px) {
    min-height: 1100px;
  }
  @media (max-width: 1850px) {
    min-height: 950px;
  }
  @media (max-width: 1750px) {
    min-height: 800px;
  }
  @media (max-width: 1450px) {
    min-height: 600px;
  }
}

.events .swiper-wrapper .swiper-slide {
  height: unset;
  /* flex: 1; */
}

.swiper-wrapper .swiper-slide {
  display: flex;
  flex-direction: column;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 4; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 7em; 
  line-height: 1.4em; 
}