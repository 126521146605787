.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fffaf8;
}
.row {
  //overriding global padding from 1.5rem to 0
  --bs-gutter-x: 0rem;
}
.container{
  --bs-gutter-x: 0rem;
  margin-left: 0;
}