$border-prop:1px solid #A37668;

@import "../../../../node_modules/bootstrap/scss/bootstrap";


.search-form{
  margin-top: 80px;
  margin-left: 20px;
  margin-right: 20px;
}
.searchContainer {
  border-bottom: $border-prop;
  margin-bottom: 20px;

}
.searchInput {
  border: none;
  outline: none;
  box-shadow: none;
}
.listItem {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.iconPlaceholder{
  width: 40px;
  height: 40px;
  border:$border-prop;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-icon{
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px;
}