@font-face {
  font-family: 'DomaineText';
  src: url("../assets/fonts/DomaineText-Black.otf") format("truetype");
  font-weight: 900; 
  font-style: (Black);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/fonts/DomaineText-BlackItalic.otf") format("truetype");
  font-weight: 900; 
  font-style: (Regular);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/fonts/DomaineText-Bold.otf") format("truetype");
  font-weight: 700; 
  font-style: (Bold);
}
/* @font-face {
  font-family: 'DomaineText';
  src: url("../assets/fonts/DomaineText-BoldItalic.otf") format("truetype");
  font-weight: 700; 
  font-style: (Regular);
} */
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/fonts/DomaineText-Light.otf") format("truetype");
  font-weight: 300; 
  font-style: (Light);
}
/* @font-face {
  font-family: 'DomaineText';
  src: url("../assets/fonts/DomaineText-LightItalic.otf") format("truetype");
  font-weight: 300; 
  font-style: (Regular);
} */
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/fonts/DomaineText-Medium.otf") format("truetype");
  font-weight: 500; 
  font-style: (Medium);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/fonts/DomaineText-MediumItalic.otf") format("truetype");
  font-weight: 500; 
  font-style: (Regular);
}
@font-face {
  font-family: 'DomaineText';
  src: url("../assets/fonts/DomaineText-Regular.otf") format("truetype");
  font-weight: 400; 
  font-style: (Regular);
}
/* @font-face {
  font-family: 'DomaineText';
  src: url("../assets/fonts/DomaineText-RegularItalic.otf") format("truetype");
  font-weight: 400; 
  font-style: (Regular);
} */


@font-face {
  font-family: 'Matter';
  src: url("../assets/fonts/Matter-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: (Bold);
}
@font-face {
  font-family: 'Matter';
  src: url("../assets/fonts/Matter-Medium.otf") format("truetype");
  font-weight: 500;
  font-style: (Medium);
}
@font-face {
  font-family: 'Matter';
  src: url("../assets/fonts/Matter-Regular.otf") format("truetype");
  font-weight: 400;
  font-style: (Regular);
}
@font-face {
  font-family: 'Matter';
  src: url("../assets/fonts/Matter-Light.otf") format("truetype");
  font-weight: 300;
  font-style: (Light);
}

/* @font-face {
  font-family: 'silverline';
  src: url("../assets/font/silverline_script.otf") format("truetype");
  font-weight: 400;
  font-style: (regular); 
} */
