$border-prop: 1px solid #a37668;
$font-family-prop: Matter;
$font-color-prop: #765348;

.footer {
  grid-area: footer;
  justify-content: start;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 8;
  font-size: 10px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 12px;
  a {
    font-size: 18px;
    // font-weight: 600;
    color: unset;
    text-decoration: none;
    line-height: 20px;
    &:hover {
      text-decoration: underline;
    }
  }
  .footer-items {
    text-align: left;
  }
}
.footer-grid {
  display: flex;
  // grid-template-rows: 1fr;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0;
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.f-icons {
  background-color: #ffffff;
  // grid-row-start: 1;
  // grid-column-start: 5;
  // grid-row-end: 2;
  // grid-column-end: 7;
  // grid-template-columns: 4;
  display: flex;
  width: 20%;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    margin: 20px 0;
  }
}

.f-links {
  background-color: #ffffff;
  grid-row-start: 1;
  grid-column-start: 2;
  width: 100%;
  margin-left: 3%;
  grid-row-end: 2;
  grid-column-end: 5;
  font-family: $font-family-prop;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
    text-align: center;
  }
}

.footer-icons {
  font-size: 15px;
  transition: all 0.2s ease-in-out;
  margin: 8px 15px;
}

.footer-icons:hover {
  transform: scale(1.3);
  margin-top: 16px;
}
